<template>
  <div class="drag" ref="dragDiv">
    <div class="tvBox">
      <div class="tvBox_box">
        <img ref="imgS" v-show="imgSwipe.cutImage" class="imgS" :src="`data:image/png;base64,${imgSwipe.cutImage}`" alt="加载失败">
        <img ref="imgB" v-show="imgSwipe.srcImage" class="imgB" :src="`data:image/png;base64,${imgSwipe.srcImage}`" alt="加载失败">
      </div>
    </div>
    <div class="drag_bg"></div>
    <div class="drag_text">{{confirmWords}}</div>
    <div ref="moveDiv" @mousedown="mouseDownFn($event)" :class="{'handler_ok_bg':confirmSuccess}" class="handler handler_bg" style="position: absolute;top: 0px;left: 0px;">
      <DArrowRight v-if="!confirmSuccess" style="width:20px;height:20px;margin-top:15px" color="#9EA4A5" />
      <SuccessFilled v-if="confirmSuccess" style="width:20px;height:20px;margin-top:15px" color="#93D8F5" />
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, nextTick } from "vue";
import { getImgSwipe, rstImgSwipe } from "@/api/public";
const emit = defineEmits(['success']);
/*距离屏幕左端距离*/
const beginClientX = ref(0);
/*触发拖动状态  判断*/
const mouseMoveState = ref(false);
/*拖动最大宽度，依据滑块宽度算出来的*/
const maxWidth = ref('');
/*滑块文字*/
const confirmWords = ref('');
/*验证成功判断*/
const confirmSuccess = ref(false);


//mousedown 事件
const mouseDownFn = (e) => {
    if(!confirmSuccess.value){
        e.preventDefault && e.preventDefault();   //阻止文字选中等 浏览器默认事件
        mouseMoveState.value = true;
        beginClientX.value = e.clientX;
    };
};
//验证成功函数
const successFunction = (width) => {
    confirmSuccess.value = true;
    confirmWords.value = '';
    if(window.addEventListener){
        document.getElementsByTagName('html')[0].removeEventListener('mousemove',mouseMoveFn);
        document.getElementsByTagName('html')[0].removeEventListener('mouseup', moseUpFn);
    }else {
        document.getElementsByTagName('html')[0].removeEventListener('mouseup',()=>{});
    }
    document.getElementsByClassName('handler')[0].style.left = width + 'px';
    document.getElementsByClassName('drag_bg')[0].style.width = width + 'px';
    emit('success',{
      captchaSerialNumber: imgSwipe.value.captchaSerialNumber,
      moveEnd_X: width,
      wbili: 1
    });
};
//mousemove事件
const mouseMoveFn = (e) => {
  if(mouseMoveState.value){
    let width = e.clientX - beginClientX.value;
    if(width >= 0 && width <= maxWidth.value){
      imgS.value.style.left = `${width}px`;
      document.getElementsByClassName('handler')[0].style.left = width + 'px';
      document.getElementsByClassName('drag_bg')[0].style.width = width + 'px';
    };
  };
};
//mouseup事件
const moseUpFn = (e) => {
  mouseMoveState.value = false;
  var width = e.clientX - beginClientX.value;
  if (!beginClientX.value) return;
  rstImgSwipe({
    captchaSerialNumber: imgSwipe.value.captchaSerialNumber,
    moveEnd_X: width,
    wbili: 1
  }).then(res => {
    if (res.code == 200) {
      if (res.data == 0) {
        document.getElementsByClassName('handler')[0].style.left = 0 + 'px';
        document.getElementsByClassName('drag_bg')[0].style.width = 0 + 'px';
        imgS.value.style.left = `0px`;
      };
      if (res.data == 1) {
        successFunction(width);
      };
      if (res.data == 2) {
        getImgSwipe().then(res => {
          imgSwipe.value = res.data;
          imgS.value.style.top = `${res.data.yposition}px`;
          document.getElementsByClassName('handler')[0].style.left = 0 + 'px';
          document.getElementsByClassName('drag_bg')[0].style.width = 0 + 'px';
          imgS.value.style.left = `0px`;
        });
      };
    };
  });
};


const dragDiv = ref(null);
const moveDiv = ref(null);
nextTick(() => {
    maxWidth.value = dragDiv.value.clientWidth - moveDiv.value.clientWidth;
    document.getElementsByTagName('html')[0].addEventListener('mousemove',mouseMoveFn);
    document.getElementsByTagName('html')[0].addEventListener('mouseup',moseUpFn)
})


// 划动验证
const imgSwipe = ref({});
const imgS = ref(null);
const imgB = ref(null);
getImgSwipe().then(res => {
  imgSwipe.value = res.data;
  imgS.value.style.top = `${res.data.yposition}px`;
});
</script>

<style lang="scss" scoped>
  .drag{
    position: relative;
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background: #F6F6F6;
    border-radius: 4px;
  }
  .tvBox {
    position: absolute;
    top: -235px;
    .tvBox_box {
      width: 330px;
      height: 220px;
      box-sizing: border-box;
      position: relative;
      .imgS {
        width:50px;
        height:50px;
        position: absolute;
        left: 0px;
        z-index: 99;
      }
      .imgB {
        width:330px;
        height:220px;
        position: absolute;
        border-radius: 8px;
        right: 0px;
        top: 0px;
      }
    }
  }
  .handler{
    width: 50px;
    height: 50px;
    cursor: move;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px 0px rgba(12,28,30,0.1);
    border-radius: 4px;
  }
  .handler_bg{
    background: #fff;
  }
  .handler_ok_bg{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background: #fff;
  }
  .drag_bg{
    background: #93D8F5;
    height: 50px;
    width: 0px;
    border-radius: 4px 0 0 4px;
  }
  .drag_text{
    position: absolute;
    top: 0px;
    width: 100%;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    -o-user-select:none;
    -ms-user-select:none;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #9EA4A5;
    letter-spacing: 0;
    text-align: center;
    font-weight: 400;
  }
</style>