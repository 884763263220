import { createStore } from 'vuex'
export default createStore({
  state: {
    isShowLogin: false,
    userInfo: {},
    token: ''
  },
  getters: {
  },
  mutations: {
    isShowLoginF(state, val) {
      state.isShowLogin = val;
    },
    userInfoF(state, val) {
      state.userInfo = val;
    },
    tokenF(state, val) {
      state.token = val;
    }
  },
  actions: {
  },
  modules: {
  }
})