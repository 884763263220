import { createRouter, createWebHashHistory } from 'vue-router';
import { userInfo, jumpLogin } from '@/api/public';
import store from '@/store/index';

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home.vue')
  },
  {
    path: '/productDetail',
    name: 'productDetail',
    component: () => import('@/views/productDetail.vue')
  },
  {
    path: '/previewYH',
    name: 'previewYH',
    component: () => import('@/views/previewYH.vue')
  },
  {
    path: '/preview',
    name: 'preview',
    component: () => import('@/views/preview.vue')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('@/views/search.vue')
  },
  {
    path: '/order',
    name: 'order',
    component: () => import('@/views/order/order.vue')
  },
  {
    path: '/orderDetail',
    name: 'orderDetail',
    component: () => import('@/views/order/orderDetail.vue')
  },
  
  // 个人中心
  {
    path: '/user',
    name: 'user',
    component:() => import('@/views/user/index.vue'),
    redirect:'/user/index',
    children:[
      // 默认页
      {
        path: '/user/index',
        name: 'personspace',
        component: () => import('@/views/user/personspace/personspace.vue')
      },
      // 订单页
      {
        path: '/user/order',
        name: 'userorder',
        component: () => import('@/views/user/order/order.vue')
      },
      // 订单详情
      {
        path: '/user/orderdetail',
        name: 'orderdetail',
        component: () => import('@/views/user/order/orderDetail.vue')
      },
      // 收藏页
      {
        path: '/user/collect',
        name: 'collect',
        component: () => import('@/views/user/collect/collect.vue')
      },
      // 编辑资料
      {
        path: '/user/edit',
        name: 'edit',
        component: () => import('@/views/user/edit/edit.vue')
      },
      // 收货地址页面
      {
        path: '/user/address',
        name: 'address',
        component: () => import('@/views/user/address/address.vue')
      },
      // 修改密码 步骤1
      {
        path: '/user/editPasswordStep1',
        name: 'editPassword',
        component: () => import('@/views/user/editPassword/editPassword.vue')
      },
      // 修改密码 步骤2
      {
        path: '/user/editPasswordStep2',
        name: 'editPassword2',
        component: () => import('@/views/user/editPassword/editPasswordStep2.vue')
      },
      // 修改密码 步骤3
      {
        path: '/user/editPasswordStep3',
        name: 'editPassword3',
        component: () => import('@/views/user/editPassword/editPasswordStep3.vue')
      },
      //物流
      {
        path: '/user/logistics',
        name: 'logistics',
        component: () => import('@/views/user/logistics/logistics.vue')
      },

    ]
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    // 始终滚动到顶部
    return { top: 0 }
  }
});

// 路由守卫--start
router.beforeEach((to, from, next) => {
  if (from.fullPath === '') {
    console.log('浏览器回退触发');
    // 在这里可以执行你想要的操作
  }
  userInfo().then(res => {
    if (res.code == 200) {
      store.commit('userInfoF', res.data);
      sessionStorage.setItem('userInfo', JSON.stringify(res.data));
      next();
    } else {
      if (to.query.loginStr) {
        jumpLogin({str: to.query.loginStr}).then(res => {
          if (res.code == 200) {
            sessionStorage.setItem('token', res.data.token);
            store.commit('tokenF', res.data.token);
            userInfo().then(res => {
              if (res.code == 200) {
                store.commit('userInfoF', res.data);
                sessionStorage.setItem('userInfo', JSON.stringify(res.data));
                next();
              } else {
                store.commit('userInfoF', '');
                sessionStorage.setItem('userInfo', JSON.stringify({}));
                next();
              };
            });
          } else {
            next('/home');
          }
        })
      } else {
        store.commit('userInfoF', '');
        sessionStorage.setItem('userInfo', JSON.stringify({}));
        if (to.path == '/productDetail' || to.path == '/home' || to.path == '/previewYH' || to.path == '/preview' || to.path == '/search') {
          next();
        } else {
          next('/home');
        }
      }
    };
  });
  
});

// 路由守卫--end
export default router;
