<template>
  <div class="foot">
    <div class="content">
      <div class="item">
        <img src="@/assets/home/promise.png" alt="">
        <div class="name">官方品质保证</div>
        <div class="text">源自 Apple 教育<br>授权经销商</div>
      </div>
      <div class="item">
        <img src="@/assets/home/send.png" alt="">
        <div class="name">免费送货上门</div>
        <div class="text">在线下单<br>顺丰直达</div>
      </div>
    </div>
    <div class="foot_text mt24">
      <span @click="handlePreview('教师优惠政策')">教师优惠政策</span>  |   
      <span @click="handlePreview('联系我们')">联系我们</span>  |   
      <span @click="handlePreview('隐私政策')">隐私政策</span>  |   
      <span @click="handlePreview('法律声明')">法律声明</span></div>
    <div class="foot_text">京ICP备18025171号-5  版权所有 Copyright © 教师商店 保留所有权利。</div>
  </div>
</template>

<script setup>
// 隐私政策
const handlePreview = (val) => {
  window.open(window.location.origin + '/#/preview?name=' + val)
};
</script>

<style lang="scss" scoped>
.foot {
  width: 100%;
  height: 278px;
  background: #F6F6F6;
  .content {
    width: 1200px;
    height: 196px;
    border-bottom: 1px solid rgba(234,234,234,1);
    margin: auto;
    display: flex;
    justify-content: center;
    .item {
      width: 320px;
      height: 196px;
      border-radius: 8px;
      margin: 0px 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 32px 0px;
      img {
        width: 50px;
        height: 50px;
      }
      .name {
        height: 22px;
        font-family: PingFangSC-Semibold;
        font-size: 16px;
        color: #000000;
        letter-spacing: 0;
        text-align: center;
        font-weight: 600;
        margin-top: 20px;
      }
      .text {
        height: 40px;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #666666;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
        margin-top: 10px;
      }
    }
  }
  .foot_text {
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: #666666;
    letter-spacing: 0;
    text-align: center;
    font-weight: 400;
    span {
      cursor: pointer;
    }
  }
  .mt24 {
    margin-top: 24px;
  }
}
</style>