<template>
  <el-config-provider :locale="locale">
    <c_header></c_header>
    <router-view></router-view>
    <c_foot></c_foot>
    <c_right></c_right>
    <el-dialog v-model="isShowLogin" :show-close="false" width="500px" :before-close="handleClose">
      <template #title>
        <div class="titleClass">
          <div class="top">教师商店</div>
          <div class="bottom">
            <div class="item">
              <img src="@/assets/productDetail/gou.png" alt="">
              <div>专属优惠</div>
            </div>
            <div class="item">
              <img src="@/assets/productDetail/gou.png" alt="">
              <div>缤纷活动</div>
            </div>
            <div class="item">
              <img src="@/assets/productDetail/gou.png" alt="">
              <div>贴心体验</div>
            </div>
          </div>
        </div>
      </template>
      <div class="content">
        <div class="title">{{ loginState == 1 ? '账号密码登录' : loginState == 2 ? '手机登录' : '手机号码注册'}}</div>
        <template v-if="loginState == 1">
          <form>
            <el-input v-model.trim="accountNumber" placeholder="账号" />
            <el-input v-model.trim="accountPassword" placeholder="密码" :type="accountEye">
              <template #suffix>
                <img v-if="accountEye == 'password'" @click="accountEye = 'text'" src="@/assets/view.png" style="width: 18px;height: 18px;" alt="" srcset="">
                <img v-else @click="accountEye = 'password'" style="width: 18px;height: 18px;" src="@/assets/hide.png" alt="" srcset="">
              </template>
            </el-input>
          </form>
          <div class="forgetPassword pointer" @click="switchHandle(2)">忘记密码</div>
        </template>
        <template v-if="loginState == 2">
          <el-input v-model.trim="phoneNumber" placeholder="手机号" />
          <el-input v-model.trim="phoneCode" placeholder="验证码">
            <template #suffix>
            <div class="yzText" v-loading="loading" @click="getCodeHandle">{{num ? `${num}s后重新发送` : '获取验证码'}}</div>
          </template>
          </el-input>
          <div class="checked pointer">
            <el-checkbox v-model="checked" label="请阅读并同意" size="large" />
            <span class="green" @click="handlePreview"> 《隐私政策》 </span>
          </div>
        </template>
        <template v-if="loginState == 3">
          <form>
            <el-input v-model.trim="registerPhone" placeholder="手机号" />
            <el-input v-model.trim="registerCode" placeholder="验证码">
              <template #suffix>
                <div class="yzText" v-loading="loading" @click="getCodeHandle">{{num ? `${num}s后重新发送` : '获取验证码'}}</div>
              </template>
            </el-input>
            <el-input v-model.trim="registerPassword" placeholder="密码" :type="registerEye">
              <template #suffix>
                <img v-if="registerEye == 'password'" @click="registerEye = 'text'" src="@/assets/view.png" style="width: 18px;height: 18px;" alt="" srcset="">
                <img v-else @click="registerEye = 'password'" style="width: 18px;height: 18px;" src="@/assets/hide.png" alt="" srcset="">
              </template>
            </el-input>
          </form>
          <div class="checked pointer">
            <el-checkbox v-model="checked1" label="请阅读并同意" size="large" />
            <span class="green">
              <span @click="handlePreview">《隐私政策》</span>
            </span>
          </div>
        </template>
        <el-button color="#02A87B" @click="loginHandle">登录</el-button>
        <div class="bot" v-if="loginState != 3">
          <span class="pointer" v-if="loginState == 1" @click="switchHandle(2)">手机验证码登录</span>
          <span class="pointer" v-if="loginState == 2" @click="switchHandle(1)">账号密码登录</span>
          <span class="line">|</span>
          <span class="pointer"  @click="switchHandle(3)">注册账号</span>
        </div>
        <div class="bot1" v-if="loginState == 3">已经有账户了？<span class="green pointer" @click="switchHandle(1)">登录</span></div>
      </div>
    </el-dialog>
    <Dialog v-model="showCode" size="small" title="验证码获取">
      <div class="slider-wrap">
        <sliderCheck @success="checkHandle"></sliderCheck>
      </div>
    </Dialog>
  </el-config-provider>
</template>

<script>
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import c_header from '@/components/header.vue';
import c_foot from '@/components/foot.vue';
import c_right from '@/components/right.vue';
import { useStore } from 'vuex';
import { ref, computed } from 'vue';
import { login, sendPhoneAfterVerify, register, loginPhone } from '@/api/public';
import { useRouter, useRoute } from 'vue-router';
import { ElMessage } from 'element-plus';
import Dialog from '@/components/dialogCopy.vue';
import sliderCheck from '@/components/slider-check';
export default {
  components: { c_header, c_foot, Dialog, sliderCheck, c_right },
  setup () {
    let locale = zhCn;
    // vuex
    const store = useStore();
    // 路由
    const router = useRouter();
    const route = useRoute();
    // 用户信息
    const userInfo = computed(() => store.state.userInfo || JSON.parse(sessionStorage.getItem('userInfo')));
    // 是否展示登录
    const isShowLogin = computed(() => store.state.isShowLogin);
    // 关闭登录回调
    const handleClose = () => {
      store.commit('isShowLoginF', false);
    };
    // 登录状态 1:密码 2:手机 3:注册
    const loginState = ref(1);
    // 切换
    const switchHandle = (val) => {
      loginState.value = val;
      num.value = 0;
      loading.value = false;
      clearInterval(numInter.value);
    }
    // 密码
    const accountNumber = ref('');
    const accountPassword = ref('');
    const accountEye = ref('password');
    // 手机
    const phoneNumber = ref('');
    const phoneCode = ref();
    const checked = ref(false);
    // 注册
    const registerPhone = ref('');
    const registerCode = ref();
    const registerPassword = ref();
    const registerEye = ref('password');
    const checked1 = ref(false);
    // 获取验证码（滑块验证）
    const loading = ref(false);
    const showCode = ref(false);
    const num = ref(0);
    const numInter = ref();
    const getCodeHandle = () => {
      if(num.value) return;
      if(loginState.value == 2 ? !phoneNumber.value : !registerPhone.value){
        ElMessage.warning('请输入手机号');
        return;
      };
      showCode.value = true;
    };
    const checkHandle = (val) => {
      loading.value = true;
      showCode.value = false;
      sendPhoneAfterVerify(
        val,
        {phone: loginState.value == 2 ? phoneNumber.value : registerPhone.value}
      ).then(res => {
        if (res.code == 200) {
          if (res.data == 0) {
            ElMessage.warning(res.message);
          };
          if (res.data == 1) {
            num.value = 300;
            numInter.value = setInterval(() => {
              num.value --;
              if (num.value == 0) {
                clearInterval(numInter.value);
              };
            }, 1000);
          };
          if (res.data == 2) {
            ElMessage.warning(res.message);
          };
        } else {
          ElMessage.warning(res.message);
        };
        loading.value = false;
      });
    };
    // 登录事件、注册
    const loginHandle = () => {
      if (loginState.value == 1) {
        login({
          account: accountNumber.value,
          password: accountPassword.value,
          spread_spid: 0
        }).then((res) => {
          if (res.code == 200) {
            sessionStorage.setItem('token', res.data.token);
            store.commit('tokenF', res.data.token);
            store.commit('isShowLoginF', false);
            router.go(0);
          } else {
            ElMessage.warning(res.message);
          };
        });
      };
      if (loginState.value == 2) {
        if(!phoneNumber.value) return ElMessage.warning('请输入手机号');
        if(!phoneCode.value) return ElMessage.warning('请输入验证码');
        if(!checked.value) return ElMessage.warning('请您阅读并同意《隐私政策》');
        loginPhone({
          captcha: phoneCode.value,
          phone: phoneNumber.value
        }).then((res) => {
          if (res.code == 200) {
            sessionStorage.setItem('token', res.data.token);
            store.commit('tokenF', res.data.token);
            store.commit('isShowLoginF', false);
            router.go(0);
          } else {
            ElMessage.warning(res.message);
          };
        });
      };
      if (loginState.value == 3) {
        if(!registerPhone.value) return ElMessage.warning('请输入手机号');
        if(!registerCode.value) return ElMessage.warning('请输入验证码');
        if(!registerPassword.value) return ElMessage.warning('请输入密码');
        if(!/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,20}$/.test(registerPassword.value)) return ElMessage.warning('密码格式为8-20位数字+字母组合');
        if(!checked1.value) return ElMessage.warning('请您阅读并同意《隐私政策》');
        register({
          captcha: registerCode.value,
          password: registerPassword.value,
          phone: registerPhone.value
        }).then((res) => {
          if (res.code == 200) {
            sessionStorage.setItem('token', res.data.token);
            store.commit('tokenF', res.data.token);
            store.commit('isShowLoginF', false);
            router.go(0);
          } else {
            ElMessage.warning(res.message);
          };
        });
      };
    };
    // 隐私政策
    const handlePreview = () => {
      window.open(window.location.origin + '/#/preview?name=隐私政策')
    };
    return {
      locale,
      isShowLogin,
      handleClose,
      loginState,
      accountNumber,
      accountPassword,
      accountEye,
      phoneNumber,
      phoneCode,
      checked,
      registerPhone,
      registerCode,
      registerPassword,
      registerEye,
      checked1,
      loginHandle,
      userInfo,
      getCodeHandle,
      showCode,
      checkHandle,
      num,
      switchHandle,
      handlePreview,
      loading
    };
  },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: PingFang SC, Microsoft YaHei, Arial, sans-serif, Helvetica Neue,
    Helvetica, Hiragino Sans GB !important;
  outline: none;
}
html,
body,
#app {
  width: 100%;
  height: 100%;
  min-width: 1200px;
  box-sizing: border-box;
  background: #ffffff;
}
ol li {
  list-style-type: decimal;
  list-style-position: inside;
}
.fit-image {
  width: 100%;  /* 设置图片宽度 */
  height: 100%;  /* 设置图片高度 */
  object-fit: contain;  /* 缩小图片并保持其宽高比例 */
}
.el-cascader-node.in-active-path,
.el-cascader-node.is-active,
.el-cascader-node.is-selectable.in-checked-path {
  color: #02a87b !important;
}
.el-radio__input.is-checked .el-radio__inner{
  border-color: #02a87b !important;
  background: #02a87b !important; 
}
:root {
  --el-color-primary: #02A87B !important;
  --el-color-primary-light-3: #1e9475 !important;
  --el-color-primary-light-5: #3ac49f !important;
  --el-color-primary-light-7: #7bdbc2 !important;
  --el-color-primary-light-8: #c8f7ea !important;
  --el-color-primary-light-9: #eefdf9 !important;
  --el-color-primary-dark-2: #03966f !important;
}
</style>
<style lang="scss" scoped>
.titleClass {
  width: 500px;
  height: 142px;
  opacity: 0.75;
  background-image: linear-gradient(137deg, #01B04E 0%, #04A0A4 100%);
  border-radius: 2px;
  margin-left: -16px;
  margin-top: -16px;
  padding: 40px 0px 24px;
  box-sizing: border-box;
  .top {
    font-family: PingFangSC-Medium;
    font-size: 28px;
    color: #FFFFFF;
    letter-spacing: 0;
    line-height: 40px;
    font-weight: 600;
    text-align: center;
  }
  .bottom {
    display: flex;
    width: 286px;
    justify-content: space-between;
    margin: auto;
    margin-top: 16px;
    .item {
      display: flex;
      align-items: center;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #FFFFFF;
      letter-spacing: 0;
      font-weight: 400;
      img {
        width: 16px;
        height: 16px;
        margin-right: 6px;
      }
    }
  }
}
.content {
  padding: 18px 56px;
  .title {
    font-family: PingFangSC-Semibold;
    font-size: 24px;
    color: #000000;
    letter-spacing: 0;
    font-weight: 600;
  }
}
::v-deep .el-input {
  line-height: 44px;
  margin-top: 24px;
  .el-input__inner {
    line-height: 44px;
    height: 44px;
  }
}
.forgetPassword {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #02A87B;
  letter-spacing: 0;
  font-weight: 400;
  margin-top: 24px;
}
::v-deep .el-button {
  height: 48px;
  font-size: 18px;
  width: 348px;
  margin-top: 40px;
}
.bot {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #02A87B;
  letter-spacing: 0;
  text-align: center;
  line-height: 20px;
  font-weight: 400;
  margin-top: 16px;
}
.line {
  color: #EAEAEA;
  margin: 0px 10px;
}
.pointer {
  cursor: pointer;
}
.checked {
  display: flex;
  align-items: center;
  margin-top: 12px;
  .green {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #02A87B;
    letter-spacing: 0;
    line-height: 20px;
    font-weight: 400;
  }
}
.bot1 {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #000000;
  letter-spacing: 0;
  text-align: center;
  line-height: 20px;
  font-weight: 400;
  margin-top: 16px;
  .green {
    color: #02A87B;
  }
}
.yzText {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #02A87B;
  letter-spacing: 0;
  text-align: right;
  font-weight: 400;
  cursor: pointer;
}
.yzText:hover {
  opacity: 0.9;
}
.slider-wrap{
  width: 350px;
  margin: 260px auto 30px;
}
</style>
