<template>
  <div class="dialog-mask" v-if="modelValue">
    <div class="dialog small">
      <div class="dialog-top">
        <span>{{ title }}</span>
        <img
          class="curPointer"
          src="@/assets/dialog-close.png"
          @click="closeDialog"
        />
      </div>
      <div class="dialog-contnet">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  title: {
    type: String,
    default: "默认标题",
  },
});

const emits = defineEmits(["update:modelValue"]);
const closeDialog = () => {
  // emits['update:modelValue']
  emits("update:modelValue", false);
};
</script>

<style scoped lang='scss'>
.dialog-mask {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba($color: #000000, $alpha: 0.4);
  z-index: 9999;
  .dialog {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 660px;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 2px;
    // padding: 24px 40px;
    &.small {
      width: 660px;
    }
    .dialog-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: PingFangSC-Semibold;
      font-size: 20px;
      color: #000000;
      letter-spacing: 0;
      font-weight: 600;
      height: 60px;
      background: #f6f6f6;
      border-radius: 2px;
      padding: 16px 24px;
      box-sizing: border-box;
      img{
        cursor: pointer;
      }
    }
    .dialog-contnet{
      padding: 24px;
      background: #fff;
    }
  }
}
</style>