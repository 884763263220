import service from '@/utils/request'

// 用户基本信息
export function userInfo (data) {
  return service({
    url: '/api/front/user',
    method: 'get',
    params: data
  })
}

// 账号密码登录
export function login (data) {
  return service({
    url: '/api/front/login',
    method: 'post',
    data
  })
}

// 退出
export function logout (data) {
  return service({
    url: '/api/front/logout',
    method: 'get',
    params: data
  })
}

// 获取验证码
export function getImgSwipe (data) {
  return service({
    url: '/api/front/getImgSwipe',
    method: 'get',
    params: data
  })
}

// 提交验证滑动验证码结果，返回0失败，1成功，2超过规定次数需要刷新
export function rstImgSwipe (data) {
  return service({
    url: '/api/front/rstImgSwipe',
    method: 'post',
    data
  })
}

// 发送手机验证码，增加滑动图片验证，返回0失败，1成功，2超过规定次数需要刷新
export function sendPhoneAfterVerify (data, data1) {
  return service({
    url: '/api/front/sendPhoneAfterVerify',
    method: 'put',
    data,
    params: data1
  })
}

// 手机号注册接口
export function register (data) {
  return service({
    url: '/api/front/register/mobile',
    method: 'post',
    data
  })
}

// 手机号登录接口
export function loginPhone (data) {
  return service({
    url: '/api/front/login/mobile',
    method: 'post',
    data
  })
}

// 手机号登录接口
export function jumpLogin (data) {
  return service({
    url: '/api/front/jumpLogin',
    method: 'get',
    params: data
  })
}
